* {
  box-sizing: inherit;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* overflow: auto;
  overflow-x: hidden; */
  scroll-behavior: smooth;
}

/* Hide scroll bar for Chrome, Safari, and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scroll bar for IE, Edge, and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
